import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClientHelper } from "../../http-client-helper";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class StoresService {

    constructor(private http: HttpClient) {
    }

    index() {
        return this.http.get(`${HttpClientHelper.baseURL}/stores`);
    }

    show(id) {
        return this.http.get(`${HttpClientHelper.baseURL}/stores/${id}`);
    }

    showByCompanyId(companyid, page = 1, limit = 10, q = '', orderby_field = 'id', orderby_seq = 'asc') {
        return this.http.get(`${HttpClientHelper.baseURL}/stores`, {params: 
            {
                companyid: companyid,
                page: page.toString(), limit: limit.toString(), q: q,
                orderby_field: orderby_field,
                orderby_seq: orderby_seq
            }
        });
    }

    update(store) {
        if(store.id)
            return this.http.put(`${HttpClientHelper.baseURL}/stores/${store.id}`, store);
        return this.http.post(`${HttpClientHelper.baseURL}/stores`, store);

    }
}