import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClientHelper } from "../../http-client-helper";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class CompanyTypesService {

    constructor(private http: HttpClient) {
    }

    index() {
        return this.http.get(`${HttpClientHelper.baseURL}/company-types`);
    }

    show(id) {
        return this.http.get(`${HttpClientHelper.baseURL}/company-types/${id}`);
    }

    update(companyType) {
        if(companyType.id)
            return this.http.put(`${HttpClientHelper.baseURL}/company-types/${companyType.id}`, companyType);
        return this.http.post(`${HttpClientHelper.baseURL}/company-types`, companyType);
    }
}