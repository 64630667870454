import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClientHelper } from "../../http-client-helper";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SegmentsService {

    constructor(private http: HttpClient) {
    }

    index(page = 1, limit = 10, q = '', orderby_field = 'name', orderby_seq = 'asc') {
        return this.http.get(`${HttpClientHelper.baseURL}/segments`, {params: 
            {page: page.toString(), limit: limit.toString(), q: q,
                orderby_field: orderby_field,
                orderby_seq: orderby_seq
            }
        });
    }

    show(id) {
        return this.http.get(`${HttpClientHelper.baseURL}/segments/${id}`);
    }

    update(segment) {
        if(segment.id)
            return this.http.put(`${HttpClientHelper.baseURL}/segments/${segment.id}`, segment);
        return this.http.post(`${HttpClientHelper.baseURL}/segments`, segment);
    }
}